<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(store)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name, true)) }} <strong>{{ $tc('labels.validation') }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
					<InputWithValidation name="name" class="mb-3" rules="required" type="text" :label="$t('fields.first_name') + ` *`" v-model="form.name" />
					<InputWithValidation name="email" class="mb-3" rules="email" type="text" :label="$t('fields.email')" v-model="form.email" />
					<InputWithValidation name="celphone" class="mb-3" rules="max:15" v-mask="'(##) #####-####'" type="text" :label="$t('labels.cel')" v-model="form.phone" />
					<InputWithValidation name="document" class="mb-3" rules="min:14|max:18" v-mask="form.doc.length > 14 ? maskCNPJ : maskCPF" type="text" :label="$t('labels.document')" v-model="form.doc" />
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import { successToast, errorToast } from '@/mixins/toast'
import eventHub from '@/services/eventHub'
import Api from '@/services/api'
import Footer from '@/components/modals/Footer'
import '@/mixins/generic'

export default {
	components: {
		Footer,
		InputWithValidation,
		ValidationObserver
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			maskCPF: '###.###.###-##',
			maskCNPJ: '##.###.###/####-##',
			loading: false,
			isOpening: false,
			form: {
				name: '',
				doc: '',
				phone: '',
				email: ''
			}
		}
	},
	methods: {
		async store() {
			try {
				this.loading = true

				if (!this.form.doc && !this.form.phone && !this.form.email) {
					errorToast('Pelo menos uma chave é necessária')
					return false
				}

				const response = await Api.post('validator/store', this.form)

				if (response.status == 201) {
					this.$emit('close')
					successToast(this.$t('alerts.create.success_f', [this.$tc('labels.validation')]))
					eventHub.$emit('reload-validators')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				} else {
					errorToast(this.$t('alerts.create.error_f', [this.$tc('labels.validation')]))
				}
			} finally {
				this.loading = false
			}
		},
		updateMask () {
			// console.log(this.form.doc.length)
			return this.form.doc.length == 11 ? '###.###.###-##' : '##.###.###/####-##'
		}
	}
}
</script>
