<template>
	<Layout>
    <b-loading :is-full-page="true" v-model="loading"></b-loading>
		<Header>
			<b-button v-if="user && user.permission_id != 1" type="is-secondary export inverted" size="is-small" rounded outlined @click="importBatch">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>{{ $t('buttons.import') }}</span>
			</b-button>
			<b-button v-if="user && user.permission_id != 1" type="is-primary create" rounded @click="newBatch">
				<svg-icon icon="plus" class="icon is-small"></svg-icon>
				<span>{{ $t('buttons.add') }}</span>
			</b-button>
		</Header>
		<filter-default
			:filters="filters"
			@filterBatch="filterBatch"
			@filterSearch="filterSearch"
			enable-search>
			<b-button v-if="filter.group && filter.group > 0" class="btn-download-batch" type="is-secondary export batch" size="is-small" rounded outlined @click="downloadGroup">
				<svg-icon class="icon is-small" icon="export"></svg-icon>
				<span>Baixar Lote</span>
			</b-button>
		</filter-default>
		<div class="table-validator">
			<b-table
				:data="validations"
				:backend-pagination="true"
				@page-change="pageChange"
				:total="total"
				:paginated="isPaginated"
				:per-page="perPage"
				:current-page.sync="currentPage"
				:pagination-simple="isPaginationSimple"
				:pagination-position="paginationPosition"
				:default-sort-direction="defaultSortDirection"
				:pagination-rounded="isPaginationRounded"
				:sort-icon="sortIcon"
				:sort-icon-size="sortIconSize"
				default-sort="name"
				aria-next-label="Next page"
				aria-previous-label="Previous page"
				aria-page-label="Page"
				aria-current-label="Current page"
				:page-input="hasInput"
				:pagination-order="paginationOrder"
				:page-input-position="inputPosition"
				:debounce-page-input="inputDebounce">
				<b-table-column field="name" :label="$tc('fields.name', 1)" v-slot="props">
					{{ props.row.name }}
				</b-table-column>
				<b-table-column v-if="user && user.permission_id == 1" field="name" :label="$tc('menu.company')" v-slot="props">
					<span class="tag is-light">{{ props.row.company.name }}</span>
				</b-table-column>
				<b-table-column field="document" :label="$t('labels.document')" width="150"  centered v-slot="props">
					<b-tooltip type="is-primary" position="is-bottom">
						<span class="circle" :class="`status-${props.row.doc_status}`">{{ props.row.doc_status }}</span>
						<template v-slot:content>
              <p v-if="props.row.doc_status == 0">Aguardando...</p>
              <p v-else-if="props.row.doc_status == 1">Não informado</p>
              <p v-else v-html="formatMsg(props.row.doc_msg)"></p>
            </template>
					</b-tooltip>
					<button class="pdf-button" :class="{ loading: props.row.doc_status == 0 }" :disabled="[1, 3].includes(props.row.doc_status)" @click="downloadProof(props.row, 'doc')">
						<svg-icon class="icon is-small" icon="export"></svg-icon>
					</button>
				</b-table-column>
				<b-table-column field="email" :label="$t('fields.email')" width="150"  centered v-slot="props">
					<b-tooltip type="is-primary" position="is-bottom">
						<span class="circle" :class="`status-${props.row.email_status}`">{{ props.row.email_status }}</span>
						<template v-slot:content>
              <p v-if="props.row.email_status == 0">Aguardando...</p>
              <p v-else-if="props.row.email_status == 1">Não informado</p>
              <p v-else v-html="formatMsg(props.row.email_msg)"></p>
            </template>
					</b-tooltip>
					<button class="pdf-button" :class="{ loading: props.row.email_status == 0 }" :disabled="[1, 3].includes(props.row.email_status)" @click="downloadProof(props.row, 'email')">
						<svg-icon class="icon is-small" icon="export"></svg-icon>
					</button>
				</b-table-column>
				<b-table-column field="phone" :label="$t('labels.cel')" width="150"  centered v-slot="props">
					<b-tooltip type="is-primary" position="is-bottom">
						<span class="circle" :class="`status-${props.row.phone_status}`">{{ props.row.phone_status }}</span>
						<template v-slot:content>
              <p v-if="props.row.phone_status == 0">Aguardando...</p>
              <p v-else-if="props.row.phone_status == 1">Não informado</p>
              <p v-else v-html="formatMsg(props.row.phone_msg)"></p>
            </template>
					</b-tooltip>
					<button class="pdf-button" :class="{ loading: props.row.phone_status == 0 }" :disabled="[1, 3].includes(props.row.phone_status)" @click="downloadProof(props.row, 'phone')">
						<svg-icon class="icon is-small" icon="export"></svg-icon>
					</button>
				</b-table-column>
				<b-table-column field="download" width="50"  centered v-slot="props">
					<button
            class="pdf-all-button"
            :disabled="(!props.row.doc_id && !props.row.email_id && !props.row.phone_id) || (props.row.doc_status == 0 || props.row.email_status == 0 || props.row.phone_status == 0)"
            @click="downloadAllProof(props.row)"
          >
						<svg-icon class="icon" icon="download"></svg-icon>
					</button>
				</b-table-column>
			</b-table>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import Icon from '@/components/Icon'
import Api from '@/services/api'
import { responsive } from '@/mixins/responsive'
import { create } from '@/mixins/modal'
import { errorToast } from '@/mixins/toast'
import Upload from '@/components/modals/UploadBatch'
import Modal from '@/components/modals/Validator'
import FilterDefault from '@/components/FilterDefault'
import eventHub from '@/services/eventHub'
import { mapState } from 'vuex'

export default {
	name: 'Validator',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		'svg-icon': Icon,
		FilterDefault
	},
	data() {
		return {
      loading: true,
      validations: [],
			isPaginated: true,
			isPaginationSimple: false,
			isPaginationRounded: false,
			paginationPosition: 'bottom',
			defaultSortDirection: 'asc',
			sortIcon: 'chevron-up',
			sortIconSize: 'is-small',
			currentPage: 1,
			perPage: 0,
			hasInput: false,
			paginationOrder: '',
			inputPosition: '',
			inputDebounce: '',
			total: 0,
			filter: {
				name: '',
				group: null,
			},
			filters: []
		}
	},
	mounted() {
		this.getBatchs()
		this.findAll(this.currentPage)

		eventHub.$on('reload-validators', () => {
			this.findAll(this.currentPage)
		})
	},
	computed: {
		...mapState('user', [
			'user'
		])
	},
	methods: {
		async getBatchs() {
			try {
				const { status, data } = await Api.get(`app/groupSelect`)
				if (status === 200) {
					let options = data.map(d => {
						return {
							label: d.description,
							value: `${d.id}`
						}
					})

					if (options.length > 0) {
						options.splice(0, 0, { label: 'Todos', value: '0' })
					}

					this.filters.push({
						title: this.$tc('labels.batch'),
						function: 'filterBatch',
						options: options
					})
				}
			} catch (e) {
				console.log(e)
			}
        },
		findAll(page) {
			this.validations = []
			this.loading = true
			Api.get(`validator/findAll?page=${page}`)
				.then(({ data }) => {
					this.currentPage = data.current_page
					this.total = data.total
					this.perPage = data.per_page
					this.validations = data.data
				})
				.catch(err => {
					console.log(err)
					errorToast(this.$t('alerts.load_error'))
				})
				.finally(() => {
					this.loading = false
				})
		},
		importBatch() {
			create(this, 'validators', Upload, 'New')
		},
		newBatch() {
			create(this, 'validators', Modal, 'New')
		},
		formatMsg(v) {
			let msg = ''

			if (v.includes('{')) {
				let msgObject = JSON.parse(v)

				if (msgObject.errorCode && msgObject.message) {
					msg = msgObject.message
				}

				if (msgObject.name) {
					msg = `<strong>Nome:</strong> ${msgObject.name}`

					msg += `${msgObject.agency ? `<br><strong>Agência:</strong> ${msgObject.agency}` : ''}`

					if (msgObject.agency)
						msg += `${msgObject.agency_digit ? `-${msgObject.agency_digit}` : '-0'}`

					msg += `${msgObject.account ? `<br><strong>Conta:</strong> ${msgObject.account}` : ''}`

					if (msgObject.account)
						msg += `${msgObject.account_digit ? `-${msgObject.account_digit}` : '-0'}`
				}
			}

			return msg
		},
		pageChange(page) {
			this.currentPage = page
			this.findAll(page)
		},
		async filterBatch (v) {
            try {
				this.loading = true
				this.validations = []

				if (v == '0') {
					this.filter.group = '0'
					this.findAll(this.currentPage)

					return false
				}

				this.filter.group = v
				const response = await Api.post('validator/filterByGroup', {
					name: this.filter.name,
					group: v
				})

				if (response.status === 200) {
					this.currentPage = response.data.current_page
					this.total = response.data.total
					this.perPage = response.data.per_page
					this.validations = response.data.data
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
		async filterSearch(v) {
			try {
				this.loading = true
				this.validations = []
				this.filter.name = v
				if (v.length > 1) {
					const response = await Api.post('validator/findByName', {
						name: this.filter.name,
						group: this.filter.group
					})

					if (response.status === 200) {
						this.currentPage = response.data.current_page
						this.total = response.data.total
						this.perPage = response.data.per_page
						this.validations = response.data.data
					}
				} else {
					this.findAll(this.currentPage)
				}
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}
		},
    async downloadProof(validation, type) {
      const id = validation.id
      const key_id = validation[`${type}_id`]
      const status = validation[`${type}_status`]

      if ([2, 4].includes(status)) {
        try {
          this.loading = true
          const res = await Api.post(`validator/downloadProof`, { id, key_id }, { responseType: 'blob' })
          this.successDownload(res, `${key_id}.pdf`)
        } catch(e) {
          this.errorDownload(e)
        } finally {
          this.loading = false
        }
      } else {
        errorToast('O status atual não permite o Download')
      }
    },
    async downloadAllProof(validation) {
      const id = validation.id
      const doc_id = validation['doc_id']
      const phone_id = validation['phone_id']
      const email_id = validation['email_id']

      if (doc_id || phone_id || email_id) {
        try {
          this.loading = true
          const res = await Api.post(`validator/downloadAllProof/${id}`, {}, { responseType: 'blob' })
          this.successDownload(res, `${id}.zip`)
        } catch(e) {
          this.errorDownload(e)
        } finally {
          this.loading = false
        }
      } else {
        errorToast('O status atual não permite o Download')
      }
    },
    async downloadGroup() {
      try {
        this.loading = true
        const id = this.filter.group
        const res = await Api.post(`validator/downloadGroup/${id}`, {}, { responseType: 'blob' })
        this.successDownload(res, `lote-${id}.zip`)
      } catch(e) {
        this.errorDownload(e)
      } finally {
        this.loading = false
      }
    },
    async errorDownload(e) {
      if (e.data.type == 'application/json') {
        const jsonData = JSON.parse(await e.data.text());
        errorToast(jsonData.message || 'Ocorreu um erro no Download')
      } else {
        errorToast('Ocorreu um erro no Download')
      }
    },
    successDownload(res, name) {
      const blob = new Blob([res.data], { type: res.data.type })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = name
      link.click()
    }
	}
}
</script>
