<template>
    <section class="filter">
        <div class="filter__wrapper">
            <b-field v-for="(f, ifl) in filters" :key="`filter-${ifl}`" :label="f.title" label-position="on-border">
                <b-select :id="`filter-${ifl}`" :placeholder="f.title" @input="filterChange(f.function, $event)">
                    <option v-for="(o, ifo) in f.options" :key="`option-${ifl}-${ifo}`" :value="o.value">{{ o.label }}</option>
                </b-select>
            </b-field>
            <slot></slot>
            <b-field v-if="enableSearch">
                <b-input id="filter-search" :placeholder="$t('buttons.search')" type="search" icon-right="magnify" @input="filterSearch"></b-input>
            </b-field>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FilterDefault',
    props: {
        enableSearch: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Array,
            default: null
        }
    },
    methods: {
        filterSearch(v) {
            this.$emit('filterSearch', v)
        },
        filterChange(func, v) {
            this.$emit(func, v)
        }
    }
}
</script>
