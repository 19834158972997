<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(save)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>
						<span class="pr-2">{{ $t('fields.upload') }}</span>
						<strong>{{ $tc('fields.file', 1) }}</strong>
					</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<div class="modal-card">
					<a href="/files/modelo.xlsx" download class="button is-secondary is-outlined is-warning mb-5">
						<svg-icon class="icon is-small" icon="export"></svg-icon>
						<span>Arquivo do Modelo</span>
					</a>
					<InputWithValidation name="name" rules="required" type="text" :label="$tc('labels.batch') + ` *`" v-model="description" />
					<b-field class="mt-3">
						<b-upload v-model="dropFiles" accept=".xlsx" multiple required drag-drop>
							<section class="section">
								<div class="content has-text-centered">
									<b-icon icon="upload" size="is-large" />
									<p>
										<span>{{ $t('fields.add_files') }}</span>
										<small class="is-block">XLSX</small>
									</p>
								</div>
							</section>
						</b-upload>
					</b-field>
					<div class="tags">
						<span v-for="(file, index) in dropFiles" :key="index" class="tag is-primary">
							<span>{{ file.name }}</span>
							<button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
						</span>
					</div>
				</div>
			</div>
			<Footer :loading="loading" />
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import { successToast, errorToast } from '@/mixins/toast'
import Icon from '@/components/Icon'
import Footer from '@/components/modals/Footer'
import eventHub from '@/services/eventHub'
import readXlsxFile from 'read-excel-file'
import Api from '@/services/api'

export default {
	name: 'Batch',
	components: {
		InputWithValidation,
		ValidationObserver,
		'svg-icon': Icon,
		Footer
	},
	data() {
		return {
			description: '',
			dropFiles: [],
			loading: false
		}
	},
	methods: {
		deleteDropFile(index) {
			this.dropFiles.splice(index, 1)
		},
		async save() {
			let errFiles = false
			let validations = []

			for (let i = 0; i < this.dropFiles.length; i++) {
				try {
					let registers = await readXlsxFile(this.dropFiles[i])

					if (registers.length > 1) {
						if (registers[0][0] == 'NOME' && registers[0][1] == 'CPF_CNPJ' && registers[0][2] == 'EMAIL' && registers[0][3] == 'CELULAR') {
							registers.shift()
							validations = [...validations, ...registers]
						} else {
							errFiles = true
							errorToast(`Erro no arquivo <strong>${this.dropFiles[i].name}</strong><br>Detalhes: O formato não está no padrão da plataforma`)
						}
					}
				} catch (e) {
					errFiles = true
					errorToast(`Erro ao ler o arquivo <strong>${this.dropFiles[i].name}</strong><br>Detalhes: ${e.message}`)
				}
			}

			if (errFiles) {
				return false
			}

			if (validations.length == 0) {
				errorToast(`Nenhum registro encontrado`)
				return false
			}

			try {
				this.loading = true

				const response = await Api.post('validator/storeGroup', { description: this.description, validations })

				if (response.status == 201) {
					this.$emit('close')
					successToast(this.$t('alerts.create.success_f', [this.$tc('labels.validation')]))
					eventHub.$emit('reload-validators')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					errorToast(message)
				} else {
					errorToast(this.$t('alerts.create.error_f', [this.$tc('labels.validation')]))
				}
			} finally {
				this.loading = false
			}
		}
	}
}
</script>
